// @flow
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

import { CustomFooter, LocalizedWithVisualFixMuiDataTable, Permission } from 'components'
import { NewPublicationButton } from 'components/NewPublicationButton'
import { getSubscriptionsDetailUrl, getSubscriptionsCreateUrl } from 'Paths'
import { useEffect, useState } from 'react'
import { dateTimeUtcToDateTallinn } from 'helpers/converter/datesTransfomer'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import { ROLE } from 'constants/auth/roles'
import { COLUMN, ROWS_PER_PAGE_OPTIONS } from 'constants/subscription/Subscription'
import { recipientCustomAddressRender } from 'shared/utils/address-util'

import { getError, getValue, isSuccess } from 'types/Try'

import type { Element } from 'react'
import type { Translator } from 'helpers'
import type { Pageable } from 'types/Pageable'
import type {
	ProductNameAndCode,
	RecipientNameAndAddress,
	RecipientDestination,
	RowsPerPageChange,
	SubscriptionDto,
	SubscriptionFilters,
	SubscriptionSort,
	SubscriptionTableRowWrapper,
	ActiveDeliveryPeriod,
	DeliveryPeriodsDetails,
	DeliveryPeriodRecipientAndAddress,
} from 'types/subscription/Subscription'
import type { Try } from 'types/Try'
import type { TableChangeAction, TableState } from 'types/components/Table'

import { getAllSubscriptions } from './serviceWrapper'
import FilterSubscriptionsView from './Filter/FilterSubscriptionsView'

import './SubscriptionListView.scss'

const COLUMN_PREFIX: string = 'column'
const NOTIFICATION_SHOW_TIME_MS: number = 10000

export type SubscriptionListViewProps = {|
	page: number,
	rowsPerPage: number,
	filters: SubscriptionFilters,
	sort: ?SubscriptionSort,
	updatePage: (page: number) => void,
	updateRowsPerPage: (change: RowsPerPageChange) => void,
	updateFilters: (filters: SubscriptionFilters | Object) => void,
	updateSort: (sort: SubscriptionSort) => void,
|}

function wrapDTOs(tableData: Array<SubscriptionDto>): Array<SubscriptionTableRowWrapper> {
	return tableData.map((value: SubscriptionDto): SubscriptionTableRowWrapper => ({
		id: value.id,
		registrantSubscriptionCode: value.registrantSubscriptionCode,
		productNameAndCode: {
			name: value?.product?.productName ?? null,
			code: value?.product?.productCode ?? null,
		},
		recipient: {
			recipient: value.deliveryPeriodsDetails?.activeDeliveries?.[0]?.recipient,
			destination: value.deliveryPeriodsDetails?.activeDeliveries?.[0]?.destination,
		},
		deliveryPeriodsDetails: value.deliveryPeriodsDetails ?? null,
		registrant: value?.registrant?.name ?? null,
		quantity: value.quantity,
		status: value.status,
	}))
}

function getFirstActiveDelivery(deliveryPeriodsDetails: ?DeliveryPeriodsDetails): ?ActiveDeliveryPeriod {
	return deliveryPeriodsDetails?.activeDeliveries?.[0]
}

function SubscriptionListView({
	page,
	rowsPerPage,
	filters,
	sort,
	updatePage,
	updateRowsPerPage,
	updateFilters,
	updateSort,
}: SubscriptionListViewProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })
	const [displayedColumns, setDisplayedColumns] = useState<{ [string]: boolean }>({
		[COLUMN.QUANTITY]: false,
		[COLUMN.STATUS]: false,
	})

	const [totalElements, setTotalElements] = useState<number>(0)
	const [data, setData] = useState<Array<SubscriptionTableRowWrapper>>([])

	useEffect((): void => {
		getAllSubscriptions(page - 1, rowsPerPage, filters, sort).then(
			(response: Try<Pageable<SubscriptionDto>>): void => {
				if (isSuccess(response)) {
					setTotalElements(response.totalElements || 0)
					const tableData = wrapDTOs(getValue(response).content)
					setData(tableData)
				} else {
					NotificationManager.error(
						getError(response).message,
						t('list.load.error'),
						NOTIFICATION_SHOW_TIME_MS
					)
				}
			}
		)
	}, [page, rowsPerPage, filters, sort, t])

	return (
		<Grid className="subscription-list-view client-area" container justify="flex-start" alignItems="flex-start">
			<Grid item xs={12}>
				<div className="title-row">
					<h3>{t('list.title')}</h3>
					<div className="button-row">
						<Permission availableForRole={ROLE.PER_SUBS_REG_VIEW}>
							<Link to={getSubscriptionsCreateUrl()}>
								<NewPublicationButton>{t('list.create.title')}</NewPublicationButton>
							</Link>
						</Permission>
					</div>
				</div>
			</Grid>
			<FilterSubscriptionsView
				onFilterSubmit={(formValues: SubscriptionFilters): void => {
					updateFilters(formValues)
				}}
				onFilterClear={(): void => {
					updateFilters({})
				}}
				initialValues={filters}
			/>
			<Grid item xs={12} className="table-grid">
				<LocalizedWithVisualFixMuiDataTable
					data={data}
					columns={[
						{
							name: COLUMN.REGISTRANT_SUBSCRIPTION_CODE,
							label: t(`${COLUMN_PREFIX}.subscription_code`),
							options: {
								customBodyRender: (value: any, tableMeta) =>
									value ? (
										<div>
											<Link to={getSubscriptionsDetailUrl(data[tableMeta.rowIndex].id)}>
												<div className="product-name">{value}</div>
											</Link>
										</div>
									) : null,
								viewColumns: false,
							},
						},
						{
							name: COLUMN.PRODUCT_NAME_AND_CODE,
							label: t(`${COLUMN_PREFIX}.product_name`),
							options: {
								customBodyRender: (value: ProductNameAndCode) => productNameAndCodeBodyRender(value),
							},
							viewColumns: false,
						},
						{
							name: COLUMN.RECIPIENT,
							label: t(`${COLUMN_PREFIX}.recipient`),
							options: {
								customBodyRender: (value: DeliveryPeriodRecipientAndAddress) =>
									recipientCustomBodyRender(value),
							},
							viewColumns: false,
						},
						{
							name: COLUMN.DELIVERY_PERIODS,
							label: t(`${COLUMN_PREFIX}.subscription_periods`),
							options: {
								customBodyRender: (value: DeliveryPeriodsDetails) => deliveryPeriodsBodyRender(value),
								sort: false,
							},
						},
						{
							name: COLUMN.REGISTRANT,
							label: t(`${COLUMN_PREFIX}.registrant`),
						},
						{
							name: COLUMN.QUANTITY,
							label: t(`${COLUMN_PREFIX}.quantity`),
							options: {
								display: displayedColumns[COLUMN.QUANTITY],
							},
						},
						{
							name: COLUMN.STATUS,
							label: t(`${COLUMN_PREFIX}.status`),
							options: {
								display: displayedColumns[COLUMN.STATUS],
							},
						},
					]}
					options={{
						serverSide: true,
						download: false,
						print: false,
						selectableRows: 'none',
						sortOrder: sort,
						search: false,
						filter: false,
						page,
						rowsPerPage,
						count: totalElements,
						customFooter: (
							count: number,
							footerPage: number,
							footerRowsPerPage: number,
							changeRowsPerPage: (value: number) => void,
							changePage: (value: number) => void
						): Element<*> => (
							<CustomFooter
								page={footerPage}
								rowsPerPage={footerRowsPerPage}
								changeRowsPerPage={changeRowsPerPage}
								changePage={changePage}
								totalElements={count}
								rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
							/>
						),
						onTableChange: (action: TableChangeAction, tableState: TableState): void => {
							if (action === 'changePage') {
								updatePage(tableState.page)
							} else if (action === 'changeRowsPerPage') {
								updateRowsPerPage({
									rowsPerPage: tableState.rowsPerPage,
									page: tableState.page,
								})
							} else if (action === 'sort') {
								const { name, direction } = tableState.sortOrder
								if (name && direction) {
									updateSort({ name, direction })
								}
							}
						},
						onViewColumnsChange: (changedColumn: string, action: string): void => {
							if (changedColumn in displayedColumns) {
								displayedColumns[changedColumn] = action === 'add'
								setDisplayedColumns(displayedColumns)
							}
						},
					}}
				/>
			</Grid>
		</Grid>
	)
}

function deliveryPeriodsBodyRender(values: DeliveryPeriodsDetails) {
	const activeDelivery: ?ActiveDeliveryPeriod = getFirstActiveDelivery(values)
	return (
		activeDelivery &&
		values && (
			<div className="flex-row">
				<div>
					<div>{formatDate(activeDelivery.appliedFrom)} -</div>
					<div>{formatDate(activeDelivery.appliedTo)}</div>
				</div>
			</div>
		)
	)
}

function recipientCustomBodyRender(deliveryPeriodRecipientAndAddress: ?DeliveryPeriodRecipientAndAddress) {
	const recipient: ?RecipientNameAndAddress = deliveryPeriodRecipientAndAddress?.recipient
	const destination: ?RecipientDestination = deliveryPeriodRecipientAndAddress?.destination
	return recipient ? (
		<div>
			<div>
				{recipient.name} {recipient.surname}
			</div>
			<div>
				{recipient.companyName} {recipient.registryCode}
			</div>
			{destination && destination.hubName && <div>{destination.hubName}</div>}
			{destination && <div>{recipientCustomAddressRender(destination)}</div>}
		</div>
	) : null
}

function productNameAndCodeBodyRender(value: ProductNameAndCode) {
	return value ? (
		<div>
			<div>
				{value.name} ({value.code})
			</div>
		</div>
	) : null
}

function formatDate(utcDate: ?string): string {
	return (utcDate && dateTimeUtcToDateTallinn(utcDate)) || ''
}

export default SubscriptionListView
