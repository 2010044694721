// @flow
import { connect } from 'react-redux'

import type { Store, ThunkAction, Dispatch } from 'types/Store'
import type { Translator } from 'helpers'

import SplashView from './SplashView'
import { logIn } from './SplashActions'

import type { SplashViewProps } from './SplashView'

type OwnProps = {|
	redirectToHomePage?: boolean,
|}

type StateProps = {||}

type DispatchProps = {|
	logIn: (redirectToHomePage: boolean, t: Translator) => ThunkAction,
|}

const mapStateToProps = (): StateProps => Object.freeze({})

const mapDispatchToProps: DispatchProps = {
	logIn,
}

export default connect<SplashViewProps, OwnProps, StateProps, DispatchProps, Store, Dispatch>(mapStateToProps, mapDispatchToProps)(SplashView)
