// @flow
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { Chip, Grid } from '@mui/material'
import { useEffect, useState } from 'react'

import { CustomFooter, LocalizedWithVisualFixMuiDataTable } from 'components'
import TitleLine from 'components/Form/TitleLine'
import { dateTimeUtcToDateTallinn } from 'helpers/converter/datesTransfomer'
import { getSubscriptionDeliveries } from 'views/Subscription/Form/Delivery/serviceWrapper'
import { getError, getValue, isSuccess } from 'types/Try'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import { DELIVERY_COLUMN, ROWS_PER_PAGE_OPTIONS } from 'constants/subscription/Subscription'

import type { Element } from 'react'
import type { Translator } from 'helpers'
import type { Try } from 'types/Try'
import type { Pageable } from 'types/Pageable'
import type {
	DeliveryDto,
	DeliveryRationaleEnum,
	DeliverySort,
	DeliveryTableRowWrapper,
	RowsPerPageChange,
	DeliveryPeriod,
	RecipientDestination,
} from 'types/subscription/Subscription'
import type { TableChangeAction, TableState } from 'types/components/Table'
import { recipientCustomAddressRender } from 'shared/utils/address-util'

const COLUMN_PREFIX: string = 'delivery.column'
const NOTIFICATION_SHOW_TIME_MS: number = 10000

export type DeliveryListViewProps = {|
	subscriptionId: ?string,
	page: number,
	rowsPerPage: number,
	sort: ?DeliverySort,
	updatePage: (page: number) => void,
	updateRowsPerPage: (change: RowsPerPageChange) => void,
	updateSort: (sort: DeliverySort) => void,
|}

function DeliveryListView({
	subscriptionId,
	page,
	rowsPerPage,
	sort,
	updatePage,
	updateRowsPerPage,
	updateSort,
}: DeliveryListViewProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })

	const [data, setData] = useState<Array<DeliveryTableRowWrapper>>([])
	const [totalElements, setTotalElements] = useState<number>(0)

	useEffect((): void => {
		getSubscriptionDeliveries(Number(subscriptionId), page - 1, rowsPerPage, sort).then(
			(response: Try<Pageable<DeliveryDto>>): void => {
				if (isSuccess(response)) {
					setTotalElements(response.totalElements || 0)
					const tableData = wrapDtos(getValue(response).content)
					setData(tableData)
				} else {
					setData([])
					NotificationManager.error(
						getError(response).message,
						t('delivery_search.fail'),
						NOTIFICATION_SHOW_TIME_MS
					)
				}
			}
		)
	}, [subscriptionId, page, rowsPerPage, sort, t])

	return (
		<div>
			<TitleLine title={t('form.subscription_delivery')} />
			<Grid className="subscription-list-view client-area" container justify="flex-start" alignItems="flex-start">
				<Grid item xs={12} className="table-grid">
					<LocalizedWithVisualFixMuiDataTable
						data={data}
						columns={[
							{
								name: DELIVERY_COLUMN.DELIVERY_PERIOD,
								label: t(`${COLUMN_PREFIX}.delivery_period`),
								options: {
									customBodyRender: (value: DeliveryPeriod) =>
										value ? (
											<div>
												{formatDate(value.deliveryStartDate)} -{' '}
												{formatDate(value.deliveryEndDate)}
											</div>
										) : null,
								},
							},
							{
								name: DELIVERY_COLUMN.DELIVERY_DESTINATION,
								label: t(`${COLUMN_PREFIX}.delivery_destination`),
								options: {
									customBodyRender: (value: RecipientDestination) =>
										value ? <div>{recipientCustomAddressRender(value)}</div> : null,
								},
							},
							{
								name: DELIVERY_COLUMN.DELIVERY_RATIONALE,
								label: t(`${COLUMN_PREFIX}.delivery_rationale`),
								options: {
									customBodyRender: (value: DeliveryRationaleEnum) =>
										value ? (
											<Chip variant="outlined" label={formatDeliveryRationale(value, t)} />
										) : null,
								},
							},
						]}
						options={{
							serverSide: true,
							download: false,
							print: false,
							selectableRows: 'none',
							sortOrder: sort,
							search: false,
							filter: false,
							page,
							rowsPerPage,
							count: totalElements,
							customFooter: (
								count: number,
								footerPage: number,
								footerRowsPerPage: number,
								changeRowsPerPage: (value: number) => void,
								changePage: (value: number) => void
							): Element<*> => (
								<CustomFooter
									page={footerPage}
									rowsPerPage={footerRowsPerPage}
									changeRowsPerPage={changeRowsPerPage}
									changePage={changePage}
									totalElements={count}
									rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
								/>
							),
							onTableChange: (action: TableChangeAction, tableState: TableState): void => {
								if (action === 'changePage') {
									updatePage(tableState.page)
								} else if (action === 'changeRowsPerPage') {
									updateRowsPerPage({
										rowsPerPage: tableState.rowsPerPage,
										page: tableState.page,
									})
								} else if (action === 'sort') {
									const { name, direction } = tableState.sortOrder
									if (name && direction) {
										updateSort({ name, direction })
									}
								}
							},
						}}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

function wrapDtos(tableData: Array<DeliveryDto>): Array<DeliveryTableRowWrapper> {
	return tableData.map((value: DeliveryDto): DeliveryTableRowWrapper => ({
		deliveryPeriod: {
			deliveryStartDate: value.appliedFrom,
			deliveryEndDate: value.appliedTo,
		},
		deliveryDestination: {
			placeName: value?.destination?.placeName ?? null,
			street: value?.destination?.street ?? null,
			houseNumber: value?.destination?.houseNumber ?? null,
			apartmentNumber: value?.destination?.apartmentNumber ?? null,
			settlement: value?.destination?.settlement ?? null,
			municipality: value?.destination?.municipality ?? null,
			smallPlace: value?.destination?.smallPlace ?? null,
			county: value?.destination?.county ?? null,
			zip: value?.destination?.zip ?? null,
		},
		deliverer: value?.hubName ?? '',
		deliveryRationale: value.deliveryRationale,
	}))
}

function formatDate(utcDate: ?string): string {
	return (utcDate && dateTimeUtcToDateTallinn(utcDate)) || ''
}

function formatDeliveryRationale(deliveryRationale: DeliveryRationaleEnum, t: Translator): string {
	return (deliveryRationale && t(`delivery_rationale.${deliveryRationale}`)) || ''
}

export default DeliveryListView
