// @flow
import { Form } from 'react-final-form'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import arrayMutators from 'final-form-arrays'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import moment from 'moment'

import { getError, getValue, isSuccess } from 'types/Try'
import { SubscriptionDeliveryRestService, SubscriptionService } from 'services'

import { LOCALE_NAMESPACE_SUBSCRIPTIONS, LOCALE_SUBSCRIPTIONS } from 'constants/locale'
import { ROLE } from 'constants/auth/roles'
import { STATUS } from 'constants/subscription/Subscription'

import type { Element } from 'react'
import type { FormRenderProps } from 'react-final-form'
import type { SubscriptionFormValues } from 'types/subscription/form/SubscriptionForm'
import type { Translator } from 'helpers'
import type { Try } from 'types/Try'
import type {
	DeliveryDto,
	GetLatestActiveSubscriptionDeliveryResponse,
	SubscriptionFieldsCheckResponse,
} from 'types/subscription/Subscription'

import { getSubscriptionsCreateUrl } from 'Paths'
import { NewPublicationButton, Permission } from 'components'
import { onSubmit } from '../SubscriptionCreateActions'
import RecipientDataSectionView from './RecipientData'
import SubscriptionDataSectionView from './SubscriptionData'
import SubscriberDataSectionView from './SubscriberData'
import SubscriptionRegistrationDataSectionView from './SubscriptionRegistration/SubscriptionRegistrationDataSectionView'
import Footer from './Footer'
import validate from './SubscriptionFormValidator'

import './SubscriptionForm.scss'

export type SubscriptionFormProps = {|
	initialValues: SubscriptionFormValues,
	isNewSubscription: boolean,
	checkFieldsResult: ?SubscriptionFieldsCheckResponse,
|}

const NOTIFICATION_SHOW_TIME_MS: number = 10000

function SubscriptionForm({ initialValues, isNewSubscription, checkFieldsResult }: SubscriptionFormProps): Element<*> {
	const { t }: { t: Translator } = useTranslation(LOCALE_NAMESPACE_SUBSCRIPTIONS, { keyPrefix: LOCALE_SUBSCRIPTIONS })

	const [minStartDate, setMinStartDate] = useState<moment>(moment())
	const [activeDelivery, setActiveDelivery] = useState<?DeliveryDto>(null)

	useEffect((): void => {
		if (initialValues.id) {
			SubscriptionDeliveryRestService.getLatestActiveSubscriptionDelivery(Number(initialValues.id))
				.then((response: Try<GetLatestActiveSubscriptionDeliveryResponse>): void => {
					if (isSuccess(response)) {
						setActiveDelivery(getValue(response)?.dto || null)
					} else {
						NotificationManager.error(getError(response).message, t('active_delivery.get.fail'), NOTIFICATION_SHOW_TIME_MS)
					}
				})
		}
		SubscriptionService.getMinimalStartDate()
			.then((startDate: moment): void => {
				setMinStartDate(startDate)
			})
			.catch((error: Error): void => {
				NotificationManager.error(error.message, t('search.fail'), NOTIFICATION_SHOW_TIME_MS)
			})
	}, [initialValues, t])

	return (
		<div className="create-subscription-form">
			{!isNewSubscription && (
				<div className="button-row">
					<Permission availableForRole={ROLE.PER_SUBS_REG_VIEW}>
						<Link to={getSubscriptionsCreateUrl()}>
							<NewPublicationButton>{t('list.create.title')}</NewPublicationButton>
						</Link>
					</Permission>
				</div>
			)}
			<Form
				disabled={isNewSubscription}
				onSubmit={onSubmit}
				mutators={{ ...arrayMutators }}
				initialValues={initialValues}
				validate={values => validate(values, minStartDate)}
				render={({ handleSubmit, form, values, invalid }: FormRenderProps<SubscriptionFormValues>): Element<*> => (
					<form onSubmit={(event) => {
						if (invalid) {
							NotificationManager.error(t('mandatory_fields_missing_title'), t('mandatory_fields_missing'), NOTIFICATION_SHOW_TIME_MS)
							event.preventDefault()
						}
						handleSubmit(event)
					}}
					>
						<Grid container justify="flex-start" alignItems="flex-start">
							<Grid className="py-3" item xs={12}>
								<div className="py-3">
									<div className="py-3">
										<RecipientDataSectionView
											form={form}
											isNewSubscription={isNewSubscription}
											isNeedsAttention={initialValues?.status === STATUS.NEEDS_ATTENTION}
											checkFieldsResult={checkFieldsResult}
										/>
										<SubscriptionDataSectionView
											form={form}
											values={values}
											isNewSubscription={isNewSubscription}
											checkFieldsResult={checkFieldsResult}
											isNeedsAttention={initialValues?.status === STATUS.NEEDS_ATTENTION}
											minStartDate={minStartDate}
										/>
										<SubscriberDataSectionView
											form={form}
											values={values}
											isNewSubscription={isNewSubscription}
										/>
										{!isNewSubscription && (
											<SubscriptionRegistrationDataSectionView form={form} isNewSubscription={isNewSubscription} />
										)}
									</div>
								</div>
							</Grid>
						</Grid>
						<Footer
							form={form}
							isNewSubscription={isNewSubscription}
							subscriptionStartDate={initialValues.subscriptionStartDate}
							activeDelivery={activeDelivery}
							isNeedsAttention={initialValues?.status === STATUS.NEEDS_ATTENTION}
						/>
					</form>
				)}
			/>
		</div>
	)
}

export default SubscriptionForm