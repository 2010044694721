// @flow
import { ROLE } from 'constants/auth/roles'

import type { Role, UserInfo } from 'types/auth'
import type { Translator } from 'helpers'

import { NotificationManager } from 'react-notifications'

export const PATH_LOGIN: string = '/login'
export const PATH_SPLASH: string = '/'
export const PATH_AUTH: string = '/auth'
export const PATH_CUSTOMER: string = '/customer'
export const PATH_CAS_REDIRECT: string = '/cas-redirect'

const SUBSCRIPTIONS_BASE = 'subscriptions'
const SORTING_PLANS_BASE = 'sorting-plans'
const NOTIFICATION_SHOW_TIME_MS: number = 10000

export const getSubscriptionsUrl = () => `/${SUBSCRIPTIONS_BASE}`
export const getSubscriptionsCreateUrl = () => `/${SUBSCRIPTIONS_BASE}/create`
export const getSubscriptionsDetailUrl = (subscriptionId?: number) =>
	`/${SUBSCRIPTIONS_BASE}/detail/${subscriptionId || ':subscriptionId'}`

export const getHomePageUrl = (userInfo: UserInfo, t: Translator): string => getHomePageUrlPath(userInfo, t)

const getHomePageUrlPath = (userInfo: UserInfo, t: Translator): string => {
	const userRoles: Set<Role> = new Set(userInfo.roles)
	if (userRoles.has(ROLE.PER_SUBS_REG_VIEW)) {
		return getSubscriptionsUrl()
	} else {
		NotificationManager.error(t('components.auth.user.role.missing.note'),
			t('components.auth.user.role.missing.title'), NOTIFICATION_SHOW_TIME_MS)
		return PATH_LOGIN
	}
}

export const getSortingPlansUrl = () => `/${SORTING_PLANS_BASE}`

export const getCustomerSelectsUrl = () => PATH_CUSTOMER
